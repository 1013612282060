/* You can add global styles to this file, and also import other style files */

@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.fast {
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@font-face {
    font-family: 'Mulish-font';
    src: url('./assets/Fonts/Mulish-VariableFont_wght.ttf');
  }

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fondoTotal {
    background-image: url(assets/Fotos/fondo.jpg) !important;
}

.borderFade:hover {
    border: 1px solid black;
}

.pointer {
    cursor: pointer;
}

.fadeIn {
    animation-name: fadeIn;
}

.letrablanca {
    color: white;
    text-align: justify;
}

.letranegra {
    color: black;
    text-align: justify;
}

.letraDescripcion {
    color: black;
    text-align: left;
}

.letraviqua {
    color: #00649A;
    font-family: "Mulish", sans-serif;
}

.centrar {
    display: inline-block;
    text-align: center !important;
    justify-content: center !important;
    align-items: center !important;
    vertical-align: middle;
}

.centrarItems {
    text-align: center !important;
    justify-content: center !important;
    align-items: center !important;
    vertical-align: middle;
}

.centrarLetras {
    text-align: center !important;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: 'Mulish-font';
    font-weight: 450;
    font-size: 105%;
    font-style: normal;
}

html,
body {
    height: 100%;
}

footer {
    position: static;
    bottom: 0px;
    width: 100%;
}

.card {
    z-index: 1;
    border: none;
}